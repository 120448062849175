import React from "react";
import Button from "react-bootstrap/Button";
import Table from "./Table";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import { Type } from "react-bootstrap-table2-editor";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Form } from "bootstrap-4-react";
import Select from "react-select";
import baseUrl, { authentication, getGrades, getTeachers } from "../Utils";
import { FaCheck } from "react-icons/fa";
import { Helmet } from "react-helmet";

const options = [
  {
    value: 2,
    label: " 2",
  },
  {
    value: 1,
    label: " 1",
  },
  {
    value: 0,
    label: " 0",
  },
  { value: "-1", label: "-1" },
  { value: "-2", label: "-2" },
  { value: "-3", label: "-3" },
  { value: "-4", label: "-4" },
  { value: "-5", label: "-5" },
];
class NewBehavior extends React.Component {
  info = [];
  reset = () => {
    this.setState({
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      classId: "",
      work_given: "",
      work_given_2: "",
      work_given_3: "",
      teacherId: "",
      studentPoints: new Map(),

      grade: "",

      classes: [],
    });
  };
  constructor(props) {
    super(props);
    this.state = {
      classId: "",
      settings: {},
      work_given: "",
      work_given_2: "",
      work_given_3: "",
      date: "",
      studentData: [],
      defaultGradeSelectVal: "default",
      defaultClassSelectVal: "default",
      defaultTeacherSelectVal: "default",
      teachers: [],
      formattedDate: "",

      teacherId: "",
      studentPoints: new Map(),
      grades: [],
      grade: "",
      editing: false,

      classes: [],
      oldColumns: [
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "behavior",
          text: "Behavior",
          sort: true,
          editable: true,
        },
        {
          dataField: "absent",
          text: "Absent",
          sort: true,
          editable: true,
        },
        {
          dataField: "work",
          text: "Work",
          sort: true,
          editable: true,
        },
        {
          dataField: "work_2",
          text: "Work 2",
          sort: true,
          editable: true,
        },
        {
          dataField: "work_3",
          text: "Work 3",
          sort: true,
          editable: true,
        },
        {
          dataField: "late",
          text: "Late",
          sort: true,
          editable: true,
        },

        {
          headerStyle: (colum, colIndex) => {
            return { width: "40%", textAlign: "center" };
          },
          dataField: "note",
          text: "Notes",
          editor: {
            type: Type.TEXTAREA,
          },
        },
      ],
      newColumns: [
        {
          dataField: "first_name",
          text: "First Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "last_name",
          text: "Last Name",
          sort: true,
          editable: false,
        },
        {
          dataField: "behavior",
          text: "Behavior",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              console.log(`current editing row id: ${row.id}`);
              console.log(`current editing column: ${column.dataField}`);
              const { marks_low, marks_high } = this.state.settings;
              let range = [];
              for (let i = marks_low; i <= marks_high; i++) {
                range.push({ value: i, label: `${i}` });
              }
              return range;
            },
          },
        },
        {
          dataField: "absent",
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return [
                { value: "unexcused", label: "unexcused" },
                { value: "absent", label: "absent" },
                { value: "present", label: "present" },
              ];
            },
          },
          text: "Absent",
          sort: true,
          editable: true,
        },
        {
          dataField: "work",
          text: "Work",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              if (!this.state.work_given)
                return [{ value: "N/A", label: "No Work Given" }];
              console.log(`current editing row id: ${row.id}`);
              console.log(`current editing column: ${column.dataField}`);
              const { work_low, work_high } = this.state.settings;
              let range = [];
              for (let i = work_low; i <= work_high; i++) {
                range.push({ value: i, label: `${i}` });
              }
              return range;
            },
          },
        },
        {
          dataField: "work_2",
          text: "Work 2",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              if (!this.state.work_given_2)
                return [{ value: "N/A", label: "No Work Given 2" }];
              console.log(`current editing row id: ${row.id}`);
              console.log(`current editing column: ${column.dataField}`);
              const { work_low, work_high } = this.state.settings;
              let range = [];
              for (let i = work_low; i <= work_high; i++) {
                range.push({ value: i, label: `${i}` });
              }
              return range;
            },
          },
        },
        {
          dataField: "work_3",
          text: "Work 3",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              if (!this.state.work_given_3)
                return [{ value: "N/A", label: "No Work Given 3" }];
              console.log(`current editing row id: ${row.id}`);
              console.log(`current editing column: ${column.dataField}`);
              const { work_low, work_high } = this.state.settings;
              let range = [];
              for (let i = work_low; i <= work_high; i++) {
                range.push({ value: i, label: `${i}` });
              }
              return range;
            },
          },
        },
        {
          dataField: "late",
          text: "Late",
          sort: true,
          editable: true,
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              console.log(`current editing row id: ${row.id}`);
              console.log(`current editing column: ${column.dataField}`);
              const { late_low, late_high } = this.state.settings;
              let range = [];
              for (let i = late_low; i <= late_high; i++) {
                range.push({ value: i, label: `${i}` });
              }
              return range;
            },
          },
        },

        {
          headerStyle: (colum, colIndex) => {
            return { width: "40%", textAlign: "center" };
          },
          dataField: "note",
          text: "Notes",
          editor: {
            type: Type.TEXTAREA,
          },
        },
      ],
    };
    //this.getClasses()
    this.getSettings();
    getGrades(this);
    getTeachers(this);
    //this.getStudents()
  }
  getSettings() {
    fetch(`${baseUrl}/settings`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        this.setState({
          settings: res.reduce(
            (pre, cur) => ({ ...pre, [cur.setting]: cur.value }),
            {}
          ),
        });
      });
  }
  // getClasses(){
  //     var url2 = new URL("http://ohrhatorah.appsenses.com/getClasses.php")
  //     fetch(url2).then(response=>{ return response.json();}).then(res=>{
  //         res.forEach((a)=>{a.id=parseInt(a.id)})
  //         this.setState({classes:res})
  //
  //     })
  // }
  getClasses(grade) {
    fetch(`${baseUrl}/grade/${grade}`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ classes: res });
      });
  }
  getOldDay(classId) {
    let date = this.state.formattedDate;
    if (date && date != "" && this.state.teacherId != "" && classId != "") {
      fetch(
        `${baseUrl}/daily-marks/${classId}/${this.state.teacherId}/${date}`,
        {
          headers: {
            Authorization: authentication,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.forEach((a) => {
            a.id = parseInt(a.id);
          });
          console.log(res);
          if (res.length > 0) {
            this.setState({
              studentData: res,
              editing: true,
              work_given: res[0].work_name,
              work_given_2: res[0].work_name_2,
              work_given_3: res[0].work_name_3,
            });
          } else {
            this.setState({ editing: false }, this.setClass);
          }
        });
    }
  }
  updateNote(i, n) {
    fetch(baseUrl + "/update-student-day-note/" + i + "/" + n, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateLate(marksId, oldMarks, newMarks) {
    fetch(
      baseUrl +
        "/update-student-day-late/" +
        marksId +
        "/" +
        newMarks +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }

  updateMarks(marksId, oldMarks, newMarks) {
    fetch(
      baseUrl +
        "/update-student-day-marks/" +
        marksId +
        "/" +
        newMarks +
        "/" +
        oldMarks,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateAbsent(marksId, status) {
    fetch(baseUrl + "/update-student-day-absence/" + marksId + "/" + status, {
      headers: {
        Authorization: authentication,
      },
    }).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateWork(marksId, oldMarks, newMarks, number) {
    if (!this.state[`work_given${number}`]) return;
    fetch(
      `${baseUrl}/update-student-day-work/${marksId}/${newMarks}${
        number ? `/${number}` : ""
      }`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateWorkGiven(number) {
    if (!this.state[`work_given${number || ""}`]) return;

    fetch(
      `${baseUrl}/update-work-name/${this.state.formattedDate}/${
        this.state[`work_given${number || ""}`]
      }${number ? `/${number}` : ""}`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  updateNoWorkGiven(number) {
    fetch(
      `${baseUrl}/update-day-no-work/${this.state.formattedDate}${
        number ? `/${number}` : ""
      }`,
      {
        headers: {
          Authorization: authentication,
        },
      }
    ).then(() => {
      this.getOldDay(this.state.classId);
    });
  }
  /*getGrades(){
            fetch(baseUrl+"/grades").then(response=>{ return response.json();}).then(res=>{

                res.forEach((a)=>{a.id=parseInt(a.id)})
                this.setState({grades:res})
            })
    }*/
  getTeachers() {
    fetch(baseUrl + "/teachers", {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });
        this.setState({ teachers: res });
      });
  }
  setClass() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.behavior = "";
          item.note = "";
          item.work = "";
          item.work_2 = "";
          item.work_3 = "";
          item.late = "";

          this.info.push(item);
        });
        this.setState({
          studentData: res,
          work_given: "",
          work_given_2: "",
          work_given_3: "",
        });
      });
  }
  print() {
    let formData = new FormData();
    formData.append("action", "get class");
    formData.append("id", this.state.classId);
    var url = "http://ohrhatorah.appsenses.com/students.php";
    url = `${baseUrl}/bare-class/${this.state.classId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
          item.absent = "";
          item.behavior = "";
          item.work = "";
          item.work_2 = "";
          item.work_3 = "";
          item.late = "";
          item.note = "";

          this.info.push(item);
        });
        this.setState({ studentData: res }, () => {
          window.print();
        });
      });
  }

  // absentFormatter = (cell, row, rowIndex) => {
  //   let data = this.state.studentData;
  //   console.log(row);
  //   data.map((d) => {
  //     if (d.id == row.id) {
  //       d.absent = !d.absent;
  //     }
  //   });

  //   this.setState({ studentData: data });
  // };
  /*{
        dataField: 'id',
        text: 'Id',
        sort: true
    },*/

  studentColumns = [
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: true,
      editable: false,
    },
    {
      dataField: "behavior",
      formatter: (cell, row, rowIndex) => {
        if (this.state.editing) {
          return cell;
        }
        return (
          <div>
            <Select
              value={cell}
              onChange={(cell) => {
                let studentPoints = this.state.studentPoints;
                studentPoints.set(row.id, cell.value);
                this.setState({ studentPoints: studentPoints });
              }}
              options={options}
            />
            {/*<input value={cell}  type="number" onChange={(e)=>{this.info.map((item)=>{if(item.id==row.id){item.point=e.target.value}});this.setState({studentData:this.info})}}/>*/}
          </div>
        );
      },
      text: "Behavior",
      sort: true,
      editable: () => {
        return this.state.editing;
      },
    },
    {
      dataField: "absent",
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return [
            { value: "unexcused", label: "unexcused" },
            { value: "absent", label: "absent" },
            { value: "present", label: "present" },
          ];
        },
      },
      //         formatter:(cell,row,rowIndex)=>{
      //             if(this.state.editing) {
      //                 if(cell==1){return <div style={{textAlign:"center"}}><FaCheck color="red" size="1.5em"/></div>}return<div></div>
      //                 /*return<div
      //                     onClick={(e)=> {
      //
      //                     }}>
      //                     <input value={cell} defaultChecked={cell} type="checkbox"/></div>
      // */
      //             }
      //
      //             return<div onClick={(e)=>{
      //                 this.state.studentData.map((item)=>{
      //                     if(item.id==row.id){item.absent=e.target.checked}});
      //                 this.setState({studentData:this.state.studentData})}}>
      //                 <input value={cell} defaultChecked={cell} type="checkbox"/></div>},
      text: "Absent",
      sort: true,
      editable: true,
    },
    {
      dataField: "work",
      text: "Work",
      sort: true,
      editable: false,
    },
    {
      dataField: "work_2",
      text: "Work 2",
      sort: true,
      editable: false,
    },
    {
      dataField: "work_3",
      text: "Work 3",
      sort: true,
      editable: false,
    },
    {
      dataField: "late",
      text: "Late",
      sort: true,
      editable: false,
    },

    {
      headerStyle: (colum, colIndex) => {
        return { width: "40%", textAlign: "center" };
      },
      dataField: "note",
      text: "Notes",
      editor: {
        type: Type.TEXTAREA,
      },
    },
  ];

  validate = () => {
    if (this.state.formattedDate == "") {
      alert("Please select a date");
    } else if (this.state.teacherId == "") {
      alert("Please select a teacher");
    } else {
      return true;
    }
    return false;
  };
  save = () => {
    if (this.validate()) {
      let obj = {};
      let classDefault = true;
      this.state.studentData.map((s) => {
        s.absent = s.absent || "present";
        console.log('behavior is: ', s.behavior, ' and marks default is: ', this.state.settings.marks_default);
        if (s.absent === "present" && s.behavior && s.behavior < this.state.settings.marks_default) {
          console.log('setting class default to false');
          classDefault = false;
        }
      });
      this.state.studentData.forEach((student) => {
        if (student.behavior || student.behavior === 0) {
          obj[student.id] = { marks: +student.behavior };
          obj[student.id].absent = student.absent;
        } else {
          if (student.absent === "present") {
            obj[student.id] = {
              marks: "D",
            };
            obj[student.id].absent = student.absent;
          } else {
            obj[student.id] = { marks: 0 };
            obj[student.id].absent = student.absent;
          }
        }

        if (student.note != undefined) {
          obj[student.id].note = student.note;
        }
        obj[student.id].work =
          +student.work || +this.state.settings.work_default || 0;
          if (this.state.work_given_2) {
        obj[student.id].work_2 =
          +student.work_2 || +this.state.settings.work_default || 0;
          } else {
            obj[student.id].work_2 = 0;
          } 
          if (this.state.work_given_3) {
        obj[student.id].work_3 =
          +student.work_3 || +this.state.settings.work_default || 0;
          } else {
            obj[student.id].work_3 = 0;
          }

        // we decided to pass it all back and let API deal with it - 4/4/22
        obj[student.id].late =
          +student.late || +student.late === 0
            ? +student.late
            : +this.state.settings.late_high || 0;
      });
      let date = this.state.formattedDate;
      let students = {
        students: obj,
        teacher: this.state.teacherId,
        date,
        class: this.state.classId,
        work_name: this.state.work_given,
        work_name_2: this.state.work_given_2,
        work_name_3: this.state.work_given_3,
        classDefault: classDefault,
      };
      students = JSON.stringify(students);

      let formData = new FormData();
      formData.append("data", students);
      

      //  console.log(students)

      ///${this.state.teacherId}/${date}/${this.state.classId}
      fetch(`${baseUrl}/new-daily-marks`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      })
        .then((response) => response.text())

        .then((text) => {
          students = "";

          if (text == "successful") {
            this.reset();
          } else {
            alert("error" + text);
          }
        });
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <style>{`
           @media print {
                    .table-bordered td, .table-bordered th {
                    border: 1px solid black !important;
                }}         
                `}</style>
        </Helmet>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>

          <Breadcrumb.Item active>New Day</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          {!this.state.editing && (
            <div style={{ paddingRight: "30px", paddingBottom: "10px" }}>
              <Button
                style={{ float: "right" }}
                onClick={() => {
                  this.save();
                }}
              >
                Save
              </Button>
              <Button
                style={{ float: "right", marginRight: "15px" }}
                onClick={() => {
                  this.print();
                }}
              >
                Print
              </Button>
            </div>
          )}
          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div
              className="col-sm-12 col-lg-4 col-xl-3"
              style={{ paddingTop: "10px" }}
            >
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Date
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={this.state.date}
                  onChange={(val, a) => {
                    let d = new Date(val).toISOString().split("T")[0];
                    console.log(d);
                    this.setState({ date: val, formattedDate: d }, () => {
                      if (this.state.classId != "") {
                        this.getOldDay(this.state.classId);
                      }
                    });
                  }}
                />
              </InputGroup>
            </div>
            <div
              className="col-sm-12 col-lg-4 col-xl-4"
              style={{ paddingTop: "10px" }}
            >
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultTeacherSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState(
                      {
                        teacherId: v.target.value,
                        defaultTeacherSelectVal: v.target.value,
                      },
                      () => {
                        if (this.state.classId != "") {
                          this.getOldDay(this.state.classId);
                        }
                      }
                    );
                  }
                }}
              >
                {this.state.teachers.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultTeacherSelectVal == "default" && (
                  <option value="default">Choose Teacher</option>
                )}
              </Form.CustomSelect>
            </div>
            <div
              className="col-sm-12 col-lg-4 col-xl-5"
              style={{ paddingTop: "10px" }}
            >
              <Form.CustomSelect
                lg
                mb="3"
                value={this.state.defaultGradeSelectVal}
                onChange={(v) => {
                  if (v.target.value != "default") {
                    this.setState({
                      grade: v.target.value,
                      defaultGradeSelectVal: v.target.value,
                      defaultClassSelectVal: "default",
                    });

                    this.getClasses(v.target.value);
                    console.log(this.state.grade);
                  }
                }}
              >
                {this.state.grades.map((x, y) => (
                  <option value={x.id} key={y}>
                    {x.name}
                  </option>
                ))}
                {this.state.defaultGradeSelectVal == "default" && (
                  <option value="default">Choose Grade</option>
                )}
              </Form.CustomSelect>
            </div>
          </div>

          <div
            className="row"
            style={{
              textAlign: "center",
              padding: "15px",
              paddingLeft: "25px",
            }}
          >
            <div className="col-sm-6" style={{ paddingTop: "10px" }}>
              {this.state.grade != "" && (
                <Form.CustomSelect
                  lg
                  mb="3"
                  value={this.state.defaultClassSelectVal}
                  onChange={(v) => {
                    if (v.target.value != "default") {
                      this.setState(
                        {
                          classId: v.target.value,
                          defaultClassSelectVal: v.target.value,
                        },
                        this.getOldDay(v.target.value)
                      );
                    }
                  }}
                >
                  {this.state.classes.map((x, y) => (
                    <option value={x.id} key={y}>
                      {x.class}
                    </option>
                  ))}
                  {this.state.defaultClassSelectVal == "default" && (
                    <option value="default">Choose Class</option>
                  )}
                </Form.CustomSelect>
              )}
            </div>
          </div>
          {this.state.studentData.length > 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <span>Work</span>
                  <input
                    style={{ border: "5px red solid" }}
                    value={this.state.work_given}
                    onChange={(e) =>
                      this.setState({ work_given: e.target.value })
                    }
                    onBlur={({ target: { value } }) => {
                      if (!this.state.editing) return;
                      value ? this.updateWorkGiven() : this.updateNoWorkGiven();
                    }}
                    placeholder="Work Given"
                  />
                </div>
                <div>
                  <span>Work 2</span>
                  <input
                    style={{ border: "5px red solid" }}
                    value={this.state.work_given_2}
                    onChange={(e) =>
                      this.setState({ work_given_2: e.target.value })
                    }
                    onBlur={({ target: { value } }) => {
                      if (!this.state.editing) return;
                      value
                        ? this.updateWorkGiven("_2")
                        : this.updateNoWorkGiven("_2");
                    }}
                    placeholder="Work Given 2"
                  />
                </div>
                <div>
                  <span>Work 3</span>
                  <input
                    style={{ border: "5px red solid" }}
                    value={this.state.work_given_3}
                    onChange={(e) =>
                      this.setState({ work_given_3: e.target.value })
                    }
                    onBlur={({ target: { value } }) => {
                      if (!this.state.editing) return;
                      value
                        ? this.updateWorkGiven("_3")
                        : this.updateNoWorkGiven("_3");
                    }}
                    placeholder="Work Given 3"
                  />
                </div>
              </div>

              <div style={{ padding: "15px" }}>
                <Table
                  pagination={false}
                  search={true}
                  selectable={false}
                  delete={false}
                  switch={false}
                  editable={true}
                  editFunc={(oldValue, newValue, row, { text, dataField }) => {
                    if (text == "Notes") {
                      if (this.state.editing) {
                        this.updateNote(row.marks_id, newValue);
                      }

                      this.info.map((item, index) => {
                        if (item.id == row.id) {
                          item.note = newValue;
                        }
                      });
                    }
                    if (dataField === "behavior" && this.state.editing) {
                      this.updateMarks(row.marks_id, oldValue, newValue);
                    }
                    if (dataField === "late" && this.state.editing) {
                      this.updateLate(row.marks_id, oldValue, newValue);
                    }
                    if (
                      dataField &&
                      dataField.startsWith("work") &&
                      this.state.editing
                    ) {
                      const number = dataField.slice(4);
                      this.updateWork(row.marks_id, oldValue, newValue, number);
                    }
                    if (dataField === "absent" && this.state.editing) {
                      this.updateAbsent(row.marks_id, newValue);
                    }
                  }}
                  columns={this.state.newColumns}
                  data={this.state.studentData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default NewBehavior;
