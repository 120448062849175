import React from "react";
import Button from "react-bootstrap/Button";

import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap-4-react";
import paginationFactory from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import Switch from "react-switch";

const { SearchBar, ClearSearchButton } = Search;

const products = [
  { id: 1, name: "zoch", price: "$5.00" },
  { id: 2, name: "zoch2", price: "$5.00" },
  { id: 354, name: "zoch", price: "$5.00" },
  { id: 3, name: "zoch2", price: "$5.00" },
  { id: 4, name: "zoch", price: "$5.00" },
  { id: 5, name: "zoch2", price: "$5.00" },
  { id: 6, name: "zoch", price: "$5.00" },
  { id: 7, name: "zoch2", price: "$5.00" },
  { id: 8, name: "zoch", price: "$5.00" },
  { id: 9, name: "zoch2", price: "$5.00" },
  { id: 10, name: "zoch", price: "$5.00" },
  { id: 11, name: "zoch2", price: "$5.00" },
  { id: 12, name: "zoch", price: "$5.00" },
  { id: 13, name: "zoch2", price: "$5.00" },
  { id: 14, name: "zoch", price: "$5.00" },
  { id: 15, name: "zoch2", price: "$5.00" },
  { id: 16, name: "zoch", price: "$5.00" },
];

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.c = this.props.columns;
    //  this.d=this.props.data
    this.state = {
      reload: false,
      c: this.props.columns,
      d: this.props.data,
      checked: false,
      selected: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.setData = this.setData.bind(this);
    this.unselectAll = this.unselectAll.bind(this);
    // this.selectRowProp=this.selectRowProp.bind(this)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ d: nextProps.data }, () => {});
    //this.node.setState({data:nextProps.data})
  }
  handleChange(checked) {
    this.setState({ checked });
  }
  setData(data) {
    this.setState({ d: data });

    if (this.props.callback != undefined) {
      // this.props.callback(this.state.d)
    }
  }

  handleGetSelectedData = () => {
    return this.node.selectionContext.selected;
  };
  handleOnSelect = (row, isSelect) => {
    if (this.props.onSelect != undefined) {
      this.props.onSelect(row, isSelect);
    }
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.id],
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter((x) => x !== row.id),
      }));
    }
  };
  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids,
      }));
    } else {
      this.setState(() => ({
        selected: [],
      }));
    }
  };

  unselectAll() {
    this.setState({ selected: [] });
  }

  render() {
    let paginationDownward = [
      {
        text: "30",
        value: 30,
      },
      {
        text: "20",
        value: 25,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: this.state.d.length,
      },
    ];
    let paginationUpward = [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: this.state.d.length,
      },
    ];
    let options = {
      // paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      hideSizePerPage: false, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      deleteBtn: this.createCustomDeleteButton,
      sizePerPageList:
        this.props.paginationDownward != undefined
          ? paginationDownward
          : paginationUpward,
    };
    const selectRowProp = {
      mode: "checkbox",
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
      ... this.props.selectRow
    }; 
    return (
      <div style={ this.props.outerStyle } >
        <ToolkitProvider
          data={this.state.d}
          columns={this.state.c}
          search={true}
          sort={true}
          keyField="id"
        >
          {(props) => (
            <div style={{ background: "#FBFBFB" }}>
              {/*<h3>Input something at below input field:</h3>*/}
              {this.props.search != false && (
                <SearchBar {...props.searchProps} />
              )}
              {this.props.search != false && (
                <ClearSearchButton {...props.searchProps} />
              )}
              {this.props.delete != false && this.state.selected.length > 0 && (
                <Button
                  className="alert-danger"
                  onClick={() => {
                    const wasDeleted = this.props.deleteClick(this.state.selected);
                    if (wasDeleted) this.setState({ selected: [] });
                  }}
                >
                  {this.props.deleteText || 'Delete'}
                </Button>
              )}
              {this.props.upload == true && (
                <Button
                  style={{ marginLeft: "10px" }}
                  className="primary"
                  onClick={() => {
                    this.props.uploadClick();
                  }}
                >
                  Upload
                </Button>
              )}
              {this.props.upload == true && (
                <Button
                  style={{ marginLeft: "10px" }}
                  variant="warning"
                  onClick={() => {
                    this.props.removeUploadedClick();
                  }}
                >
                  Remove Uploaded
                </Button>
              )}
              {this.props.add == true && (
                <Button
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                    marginTop: "10px",
                    float: "right",
                  }}
                  variant="info"
                  onClick={() => {
                    this.props.addFunction();
                  }}
                >
                  Add
                </Button>
              )}

              {this.props.switch != false && (
                <label style={{ float: "right" }}>
                  <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                  />
                  <span style={{ verticalAlign: "top", paddingLeft: "3px" }}>
                    Edit Mode
                  </span>
                </label>
              )}
              {/*<hr />*/}

              <BootstrapTable
                {...(this.state.checked || this.props.editable == true
                  ? this.props.editVerify == true
                    ? {
                        cellEdit: cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          beforeSaveCell: (
                            oldValue,
                            newValue,
                            row,
                            column,
                            done
                          ) => {
                            if (oldValue != newValue) {
                              if (
                                this.props.editFuncVerify(
                                  oldValue,
                                  newValue,
                                  row,
                                  column,
                                  done
                                ) == false
                              ) {
                                done(false);
                              }
                            }
                          },
                        }),
                      }
                    : {
                        cellEdit: cellEditFactory({
                          mode: "click",
                          blurToSave: true,
                          beforeSaveCell: (oldValue, newValue, row, column) => {
                            if (oldValue != newValue) {
                              this.props.editFunc(
                                oldValue,
                                newValue,
                                row,
                                column
                              );
                            }
                          },
                        }),
                      }
                  : { rowEvents: this.props.rowEvents })}
                style={{ backgroundColor: "white !important" }}
                striped
                hover
                condensed
                pagination={
                  this.props.pagination != false && paginationFactory(options)
                }
                {...(this.props.selectable != false
                  ? { selectRow: selectRowProp }
                  : {})}
                {...(this.props.defaultSorted != undefined && {
                  defaultSorted: this.props.defaultSorted,
                })}
                ref={(n) => (this.node = n)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    );
  }
}

export default Table;
